import React from 'react';
import axios from 'axios';
import ReactCrop from 'react-image-crop';

import 'react-image-crop/lib/ReactCrop.scss';

export const BEARER_TOKEN = window.getCookieProfileImage('token') ? window.getCookieProfileImage('token') : process.env.REACT_APP_JWT_TOKEN ? process.env.REACT_APP_JWT_TOKEN : ''
export const SHORT_TOKEN = window.getCookieProfileImage('token_short') ? window.getCookieProfileImage('token_short') : process.env.REACT_APP_JWT_TOKEN ? process.env.REACT_APP_JWT_TOKEN : ''

class App extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: true,
      editMode: false,
      src: null,
      originalSrc: null,
      initials: null,
      crop: {
        unit: '%',
        width: 30,
        aspect: 1 / 1,
      }
    }
  }
  componentDidMount() {
    // Get current profile photo (if any)
    if (BEARER_TOKEN && SHORT_TOKEN) {
      axios.get(
        `${window.language}/wp-json/users/v1/session?token=${SHORT_TOKEN}`,
        {
          headers: {
            'Authorization': `Bearer ${BEARER_TOKEN}`,
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }
      ).then((response) => {
        console.log('response.data (session)', response?.data)
        if (typeof response?.data?.profile_photo !== 'undefined' && response?.data?.profile_photo && response?.data?.profile_photo?.indexOf("default-profile-photo.png") <= -1) {
          this.setState({
            isLoading: false,
            originalSrc: `${response.data.profile_photo}?time=${new Date().getTime()}`
          })
        } else {
          response?.data?.initials?.[0] ? this.setState({
            isLoading: false,
            originalSrc: null,
            initials: response.data.initials[0]
          }, () => {
            console.log("State", this.state)
          }) : console.log("State", this.state)
        }
      }).catch((error) => {
        console.log("Error", error)
        console.log("State", this.state)
      })
    }
  }
  onSelectFile = e => {
    console.log('onSelectFile', e)
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        this.setState({ src: reader.result })
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  // If you setState the crop in here you should return false.
  onImageLoaded = image => {
    this.imageRef = image;
    if (typeof window.ADDITIONAL_PROFILE_PHOTO !== 'undefined') {
        this.cropLargeImg();
    }
  };

  onCropComplete = crop => {
    console.log('onCropComplete')
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedBase64 = await this.getCroppedImg(
        this.imageRef,
        crop,
        'newFile.jpeg'
      );
      console.log('croppedBase64', crop, croppedBase64)
      this.setState({ croppedImageUrl: croppedBase64 });
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error('Canvas is empty');
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);

        var reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function() {
            var base64data = reader.result;
            resolve(base64data);
        }
      }, 'image/jpeg');
    });
  }

  async cropLargeImg() {
      if (this.imageRef) {
          const largeBase64 = await this.getLargeImg(
              this.imageRef,
              'newFileLarge.jpeg'
          );
          this.setState({ largeImageUrl: largeBase64 });
      }
  }

  getLargeImg(image, fileName) {
    const canvas = document.createElement('canvas');
    if (image.naturalWidth >= image.naturalHeight) {
        canvas.width = parseInt(window.ADDITIONAL_PROFILE_PHOTO.largeSide) || 640;
        canvas.height = parseInt(window.ADDITIONAL_PROFILE_PHOTO.shortSide) || 480;
    } else {
        canvas.width = parseInt(window.ADDITIONAL_PROFILE_PHOTO.shortSide) || 480;
        canvas.height = parseInt(window.ADDITIONAL_PROFILE_PHOTO.largeSide) || 640;
    }

    const imageWidth = image.naturalWidth;
    const imageHeight = image.naturalHeight;
    const scale = Math.min(canvas.width / imageWidth, canvas.height / imageHeight);
    const scaledWidth = imageWidth * scale;
    const scaledHeight = imageHeight * scale;

    const ctx = canvas.getContext('2d');

    ctx.drawImage(
        image,
        (canvas.width - scaledWidth) / 2,
        (canvas.height - scaledHeight) / 2,
        scaledWidth,
        scaledHeight
    );

    return new Promise((resolve, reject) => {
        canvas.toBlob(blob => {
            if (!blob) {
                //reject(new Error('Canvas is empty'));
                console.error('Canvas is empty');
                return;
            }
            blob.name = fileName;
            window.URL.revokeObjectURL(this.fileUrl);
            this.fileUrl = window.URL.createObjectURL(blob);

            var reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = function() {
                var base64data = reader.result;
                resolve(base64data);
            }
        }, 'image/jpeg');
    });
  }

  upload() {
    let base64 = this.state.croppedImageUrl

    this.setState({
      isLoading: true,
      src: null,
      croppedImageUrl: null,
      originalImage: null
    })

    var form_data = new FormData();

    form_data.append(`image`, base64);

    if (typeof window.ADDITIONAL_PROFILE_PHOTO !== 'undefined') {
      form_data.append(`imageLarge`, this.state.largeImageUrl);
    }

    axios.post(
      `${window.language}/wp-json/users/v1/photo?token=${BEARER_TOKEN}`,
      form_data,
      {
        headers: {
          'Authorization': `Bearer ${BEARER_TOKEN}`,
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
    ).then((response) => {
      console.log('response.data', response.data)
      if (typeof response.data.success !== 'undefined' && response.data.success) {

        // Update other instances of .control-profile .img too.
        let elements = document.querySelectorAll('#main-sidebar-controls .control-profile .img');
        elements.forEach((element) => {
          element.style.backgroundImage = `url(${element.style.backgroundImage.split('"')[1]}?time=${new Date().getTime()})`
        })

        this.setState({
          isLoading: false,
          originalSrc: base64,
          initials: null
        })

        try{
          window.trackEvent('profile', 'new_image', 'success', 1)
        }
        catch(e){
        }

      } else {
        try{
          window.trackEvent('profile', 'new_image', 'failed', 1)
        }
        catch(e){
        }
      }
    })
  }

  render() {
    return (
      <div className={`profile-form-header ${ this.state.isLoading ? `loading` : `` }`}>
        <div className="control-profile">
          <div className="control-profile-inner">
            <span className="screen-reader-text">Change profile image</span>
            {
              this.state.originalSrc === null && this.state.initials !== null ?
              <div className="img profile_initials">
              {
                this.state.initials !== "" && this.state.initials !== " " ?
                this.state.initials
                :
                <svg width="28" height="28" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
              }
              </div>
              :
              this.state.originalSrc && !this.state.src ? <div className="img" style={{ backgroundImage: `url(${this.state.originalSrc})` }}></div> : !this.state.originalSrc && !this.state.src ? <div className="img"></div> : null
            }
            <input ref={ (ref) => this.inputRef = ref } style={{ display: 'none' }} type="file" accept="image/*" onChange={this.onSelectFile} />
            {this.state.src && (
              <ReactCrop
                src={this.state.src}
                crop={this.state.crop}
                ruleOfThirds
                onImageLoaded={this.onImageLoaded}
                onComplete={this.onCropComplete}
                onChange={this.onCropChange}
              />
            )}
            {this.state.croppedImageUrl && (
              <span className="control-profile-control accept" onClick={(ev) => { ev.preventDefault(); this.upload()}}>Accept</span>
            )}
            {this.state.croppedImageUrl && (
              <span className="control-profile-control decline" onClick={(ev) => { ev.preventDefault(); this.setState({ croppedImageUrl: false, src: null })}}>Cancel</span>
            )}
          </div>
        </div>
        { !this.state.croppedImageUrl ? <span className="control-profile-link" onClick={(ev) => { ev.preventDefault(); this.inputRef.click()}}>Change</span> : null }
        <strong><span data-field-key="first_name"></span> <span data-field-key="last_name"></span></strong>
      </div>
    );
  }
}

export default App;
